import { DatePipe, getCurrencySymbol } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as cloneDeep from "lodash/cloneDeep";
import { TypeaheadMatch } from "ngx-bootstrap";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AddOrderEntityRequest, EntityRequestData } from "src/app/shared/models/service-request/order-entity-request.model";
import { Entity, EntityResponse, OrderEntities } from "src/app/shared/models/services-response/entity-response.model";
import { GenerateLabelsResponse } from "src/app/shared/models/services-response/generate-labels-response.model";
import { History, OrderHistoryResponse } from 'src/app/shared/models/services-response/order-history.model'
import { User, UsersResponse } from "src/app/shared/models/services-response/login-response.model";
import { CommonService } from "src/app/shared/services/common.service";
import {
  Address,
  Fulfillmentcenter,
  Group,
  Label,
  Note, OrderUpdateRequest, Vendor
} from "../../../shared/models/service-request/order-update-request.model";
import { Order, OrderEntityResponse, OrderResponse } from "../../../shared/models/services-response/order-response.model";
import { DropDownList } from "../../../shared/models/util.model";
import {
  OrderLabelPipe, OrderTrackIdsPipe
} from "../../../shared/pipes/dropdown-data-pipe";
import { OrderService } from "../../../shared/services/order.service";
import { FinancialStatusResponse } from "src/app/shared/models/services-response/financialStatus.response.model";

@Component({
  selector: "app-order-update",
  templateUrl: "./order-update.component.html",
  styleUrls: ["./order-update.component.scss"],
})
export class OrderUpdateComponent implements OnInit {
  order = {} as Order;
  orderId;
  allLabels;
  orderLabel;
  DropdownSetings;
  labelIdsList: DropDownList[];
  orderLabels: DropDownList[];
  groupIds: DropDownList[];
  deliveryChannels: DropDownList[];
  fulfillmentCentersList: DropDownList[];
  updateOrderForm: FormGroup;
  orderTrackIds;
  orderUpdate = {} as OrderUpdateRequest;
  $: any;
  readOnly: false;
  isUpdatingOrder = false;
  isPrintingOrder = false;
  isPrintingLineItem = -1;
  selectedLabel;
  lineEdit = false;
  orderCopy: any;
  editflag = true;
  mailNotificationFlag = false;
  printOrdermsg = 'Print Order';


  updateStatus: boolean = false;
  rule:boolean = true;


  orderHistory: History[] = [];
  historyLoading: boolean = true

  // entity management
  entities: Entity[] = [] as Entity[];
  selectedEntityType: number;
  selectedEntity: number;
  selectedEntityDetails: OrderEntities = {} as OrderEntities;
  addEntityObj: EntityRequestData = {} as EntityRequestData;
  orderEntities: OrderEntities[] = [] as OrderEntities[];
  users: User[] = [] as User[];
  editMode: boolean = false;


  paymentDropdownData: String[];
  currencySymbol:string;



  constructor(
    public modelRef: BsModalRef,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private orderLabelPipe: OrderLabelPipe,
    private orderTrackIdsPipe: OrderTrackIdsPipe,
    private orderService: OrderService,
    private toast: ToastrService,
    private service: CommonService

  ) { }

  ngOnInit() {
    this.getFinancialStatus();
    this.getUsers()
    this.getEntityList();
    this.getOrderEntities();
    this.getOrderHistory(this.orderId)
    this.orderLabel = this.order.label.slice(0);
    this.DropdownSetings = {
      singleSelection: false,
      idField: "code",
      textField: "text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 0,
      allowSearchFilter: true,
      maxHeight: "10px",
    };
    this.updateOrderForm = this.formBuilder.group({
      orderId: this.order.orderId,
      orderTrackIds: "",
      allLabelsList: "",
      orderGroups: this.order.group ? this.order.group.groupId : "",
      addressName: this.order.shippingAddress ? this.order.shippingAddress.name : "",
      addressTitle: this.order.shippingAddress ? this.order.shippingAddress.title : "",
      addressCity: this.order.shippingAddress ? this.order.shippingAddress.city : "",
      addressZip: this.order.shippingAddress ? this.order.shippingAddress.zip : "",
      addressPhone: this.order.shippingAddress ? this.order.shippingAddress.phone.number : "",
      addressField1: this.order.shippingAddress ? this.order.shippingAddress.address1 : "",
      addressField2: this.order.shippingAddress ? this.order.shippingAddress.address2 : "",
      addressProvince: this.order.shippingAddress ? this.order.shippingAddress.province : "",
      addressCountry: this.order.shippingAddress ? this.order.shippingAddress.country : "",
      orderNoteTitle: "",
      orderLongitude: this.order.shippingAddress ? this.order.shippingAddress.longitude : "",
      orderLatitude: this.order.shippingAddress ? this.order.shippingAddress.latitude : "",
      orderNote: "",
      customerNote: this.order.note ? this.order.note : "",
      orderDeliveryChannel: this.order.deliveryChannelId,
      orderFulFillmentCenter: this.order.fulfillmentcenter ? this.order.fulfillmentcenter.centerId : "",
      ordervendor: this.order.vendor.vendorName,
      orderDeliveryDate: this.order.deliveryDate ? this.datePipe.transform(this.order.deliveryDate, "yyyy-MM-dd").toString() : "",
      orderDispatchDate: this.order.dispatchDate ? this.datePipe.transform(this.order.dispatchDate, "yyyy-MM-dd").toString() : "",
      orderStatus: '',
      entityList: 1,
      userEntity: "",
      entityStatus: "Active",
      financialStatus: this.order.financialStatus ? this.order.financialStatus.toUpperCase() : "",
    });

    if (this.readOnly) {
      this.updateOrderForm.get("orderDeliveryChannel").disable();
      this.updateOrderForm.get("orderFulFillmentCenter").disable();
      this.updateOrderForm.get("orderGroups").disable();
    }
    this.orderLabels = this.orderLabelPipe.transform(this.order.label);
    this.orderTrackIds = this.orderTrackIdsPipe.transform(this.order.trackingId);
    this.orderCopy = cloneDeep(this.order);
    this.setlineitemsValue();
    this.currencySymbol = getCurrencySymbol(this.order.currency,'narrow');
  }

  getFinancialStatus() {
    this.orderService.getFinancialStatus().subscribe((response: FinancialStatusResponse) => {
      let dropdown: String[] = [];
      if (response.acknowledgement.status == "SUCCESS") {
        response.financialStatus.forEach((status: string) => dropdown.push(status))
        this.paymentDropdownData = dropdown;
      }
    })
  }
  getUsers() {
    this.service.getDeliveryUsers().subscribe((res: UsersResponse) => {
      if (res.acknowledgement.status === "Success") {
        this.users = res.users;
      }
      else {
        this.pushToast("Failed Fetching Users", res.acknowledgement.message, "error");
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast("Unexpected Error!", "Couldn't fetch users", "error");
    })
  }

  addConfirm(){
    if(confirm("Do you  want to add the order entity ?")){
      this.addOrderEntity(this.updateOrderForm.value);
    }
  }

  updateConfirm(){
    if(confirm("Do you  want to update the order entity ?")){
      this.addOrderEntity(this.updateOrderForm.value);
    }
  }

  addOrderEntity(form) {
    let entityId = form.entityList;
    let entitySrc = form.userEntity;
    let entityStatus = form.entityStatus;
    let UserEntity = this.users.filter((user) => user.userId == entitySrc)[0];

    if (entityId && entitySrc) {
      this.addEntityObj.entityId = entityId;
      this.addEntityObj.entitySrcId = entitySrc.toString();
      this.addEntityObj.orderEntityId = (this.selectedEntityDetails?.orderEntityId) ? this.selectedEntityDetails.orderEntityId : null;
      this.addEntityObj.status = entityStatus;
      this.addEntityObj.additionalInfo = UserEntity.name;
      let orderEntities: EntityRequestData[] = [] as EntityRequestData[];
      orderEntities.push(this.addEntityObj)
      let requestBody: AddOrderEntityRequest = {} as AddOrderEntityRequest;
      requestBody.orderEntities = orderEntities;
      this.addEditEntity(requestBody);


    }
    else {
      this.pushToast("Cannot Proceed", "Please add all the details to add entity", "error");
    }
  }

  addEditEntity(requestBody) {
    this.orderService.addOrderEntity(this.order.orderId, requestBody).subscribe((res: OrderEntityResponse) => {
      if (res.acknowledgement.status === "SUCCESS") {
        this.setEntityTable(res.orderEntities);
        this.updateOrderForm.get('entityList').patchValue(1);
        this.updateOrderForm.get('userEntity').patchValue("");
        this.updateOrderForm.get('entityStatus').patchValue("Active");
        this.pushToast("Success!", "Successfully updated the order entity list", "success");
        this.editMode = false;
      }
      else {
        this.pushToast("Failed adding entity", res.acknowledgement.message, "error");
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast(error.error.acknowledgement.status, error.error.acknowledgement.message, "error");
    })
  }

  setEntityTable(entities: OrderEntities[]) {
    this.orderEntities = entities;
  }

  getOrderEntities() {
    this.orderService.fetchOrderEntities(this.order.orderId).subscribe((res: OrderEntityResponse) => {
      if (res.acknowledgement.status === "SUCCESS") {
        this.setEntityTable(res.orderEntities);
      }
      else {
        this.pushToast(res.acknowledgement.status, res.acknowledgement.message, "error");
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast("Unexpected Error!", "Entity Fetch Failed", "error");
    })
  }

  getEntityList() {
    this.orderService.fetchEntities().subscribe((res: EntityResponse) => {
      if (res.acknowledgement.status === "SUCCESS") {
        this.entities = res.entities;
      }
      else {
        this.pushToast("Failed Fetching Entities", res.acknowledgement.message, "error");
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast("Unexpected Error!", "Fetching Entity List Failed!", "error");
    })
  }

  editEntity(entity: OrderEntities) {
    this.editMode = true;
    this.selectedEntityDetails = entity;
    this.updateOrderForm.get('entityList').patchValue(entity.entityId);
    this.updateOrderForm.get('userEntity').patchValue(entity.entitySrcId.toString());
    this.updateOrderForm.get('entityStatus').patchValue(entity.status);
  }

  updateEntityStatus(event, entityReceived: OrderEntities) {
    let entity = JSON.parse(JSON.stringify(entityReceived));
    let orderEntities: OrderEntities[] = [] as OrderEntities[];
    entity.status = event ? "Active" : "Inactive";
    let requestBody: AddOrderEntityRequest = {} as AddOrderEntityRequest;
    orderEntities.push(entity);
    requestBody.orderEntities = orderEntities;
    this.addEditEntity(requestBody);
  }

  setlineitemsValue() {
    this.orderCopy.lineItems = this.orderCopy.lineItems.map((value, index, array) => {
      return {
        skuId: this.orderCopy.lineItems[index].skuId,
        skuName: this.orderCopy.lineItems[index].skuName,
        channelProductId: this.orderCopy.lineItems[index].channelProductId,
        lineItemId: this.orderCopy.lineItems[index].lineItemId,
        batchNumber: this.orderCopy.lineItems[index].batchNumber,
        unitPrice: this.orderCopy.lineItems[index].unitPrice?.toFixed(2),
        channelLineItemId: this.orderCopy.lineItems[index].channelLineItemId,
        quantity: this.orderCopy.lineItems[index].quantity,
        totalPrice: this.orderCopy.lineItems[index].totalPrice?.toFixed(2),
        fulfillmentStatus: this.orderCopy.lineItems[index].fulfillmentStatus,
        totalDiscount: this.orderCopy.lineItems[index].totalDiscount?.toFixed(2),
        shortName: this.orderCopy.lineItems[index].shortName,
        taxAmount: this.orderCopy.lineItems[index].taxAmount?.toFixed(2),
        taxRate: "0",
        longName: this.orderCopy.lineItems[index].longName,
        unit: this.orderCopy.lineItems[index].unit
      };
    });
  }
  public getOrder(): Boolean {
    this.orderService.getOrder(this.orderId).subscribe((data: OrderResponse) => {
      this.order = data.order[0];
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast("Unexpected Error!", "Fetching order Details Failed.", "error");
    });
    return true;
  }

  public getOrderHistory(oId) {
    this.historyLoading = true;
    this.orderService.getOrderHistoty(oId).subscribe((response: OrderHistoryResponse) => {
      this.historyLoading = false;
      this.orderHistory = response.history;
    }, (error: HttpErrorResponse) => {
      this.historyLoading = false;
      const config = { closeButton: true, timeOut: 2000, positionClass: "toast-top-right", extendedTimeOut: 5000 }
      this.toast.error(error.statusText + " : Fetching Order History", error.error.acknowledgement.message, config)
    })
  }


  public updateOrder(form) {
    this.orderUpdate.address = {} as Address
    this.orderUpdate.group = {} as Group
    this.orderUpdate.label = [] as Label[]
    this.orderUpdate.vendor = {} as Vendor
    this.orderUpdate.notes = [] as Note[]
    this.orderUpdate.fulfillmentcenter = {} as Fulfillmentcenter
    this.orderUpdate.address.city = form.addressCity
    this.orderUpdate.address.addressId = this.order.shippingAddress.addressId
    this.orderUpdate.address.country = form.addressCountry
    this.orderUpdate.address.name = form.addressName
    this.orderUpdate.address.title = form.addressTitle;
    this.orderUpdate.address.phone = form.addressPhone
    this.orderUpdate.address.province = form.addressProvince
    this.orderUpdate.address.zip = form.addressZip
    this.orderUpdate.address.address1 = form.addressField1
    this.orderUpdate.address.address2 = form.addressField2
    this.orderUpdate.address.addressType = "shipping"
    this.orderUpdate.deliveryDate = this.datePipe.transform(form.orderDeliveryDate, "dd/MM/yyyy")
    this.orderUpdate.dispatchDate = this.datePipe.transform(form.orderDispatchDate, "dd/MM/yyyy")
    this.orderUpdate.financialStatus = form.financialStatus
    this.orderUpdate.rule = this.rule
    // Assign values for disabled fields
    if (this.readOnly) {
      this.orderUpdate.group.groupId = this.order.group ? this.order.group.groupId : "";
      this.orderUpdate.deliveryChannelId = this.order.deliveryChannelId;
      this.orderUpdate.fulfillmentcenter.centerId = this.order.fulfillmentcenter ? this.order.fulfillmentcenter.centerId : "";
    } else {
      this.orderUpdate.deliveryChannelId = form.orderDeliveryChannel;
      this.orderUpdate.group.groupId = form.orderGroups;
      this.orderUpdate.fulfillmentcenter.centerId = form.orderFulFillmentCenter;
    }
    this.orderUpdate.vendor.vendorName = form.ordervendor;
    this.orderUpdate.address.latitude = form.orderLatitude;
    this.orderUpdate.address.longitude = form.orderLongitude;
    this.orderUpdate.orderNotes = form.customerNote;
    this.orderUpdate.lineItems = this.orderCopy.lineItems.map((value, index, array) => {
      return {
        lineItemId: this.orderCopy.lineItems[index].lineItemId,
        batchNumber: this.orderCopy.lineItems[index].batchNumber,
        unitPrice: this.orderCopy.lineItems[index].unitPrice ? this.orderCopy.lineItems[index].unitPrice.toString() : "0.00",
        channelLineItemId: this.orderCopy.lineItems[index].channelLineItemId,
        description: this.orderCopy.lineItems[index].description,
        quantity: this.orderCopy.lineItems[index].quantity,
        totalPrice: this.orderCopy.lineItems[index].totalPrice ? this.orderCopy.lineItems[index].totalPrice.toString() : "0.00",
        fulfillmentStatus: this.orderCopy.lineItems[index].fulfillmentStatus,
        totalDiscount: this.orderCopy.lineItems[index].totalDiscount ? this.orderCopy.lineItems[index].totalDiscount.toString() : "0.00",
        shortName: this.orderCopy.lineItems[index].shortName,
        taxAmount: this.orderCopy.lineItems[index].taxAmount ? this.orderCopy.lineItems[index].taxAmount.toString() : "0.00",
        taxRate: "0",
        longName: this.orderCopy.lineItems[index].longName,
        unit: this.orderCopy.lineItems[index].unit
      };
    });
    this.orderUpdate.cost = {
      totalLineItemsPrice: this.orderCopy.totalLineItemsPrice ? this.orderCopy.totalLineItemsPrice.toString() : "0.00",
      totalPrice: this.orderCopy.totalPrice ? this.orderCopy.totalPrice.toString() : "0.00",
      subTotalPrice: this.orderCopy.subTotalPrice ? this.orderCopy.subTotalPrice.toString() : "0.00",
      totalTax: this.orderCopy.totalTax ? this.orderCopy.totalTax.toString() : "0.00",
      totalDiscount: this.orderCopy.totalDiscount ? this.orderCopy.totalDiscount.toString() : "0.00",
      totalShippingPrice: this.orderCopy.totalShippingPrice ? this.orderCopy.totalShippingPrice.toString() : "0.00",
    };
    let dateNow = this.datePipe.transform(new Date(), "dd/MM/yyyy, h:mm a");
    this.orderUpdate.notes.push({ noteKey: dateNow, noteValue: form.orderNote });
    if (this.orderLabel.length > 0) {
      this.orderLabel.forEach((label) => {
        this.orderUpdate.label.push({ labelId: label.labelId, labelAction: "add" });
      });
    } else if (this.orderLabel.length < 1 && this.order.label.length > 0) {
      this.orderUpdate.label = [];
    } else {
      this.orderUpdate.label = null;
    }
  //   if(this.orderUpdate.label != null){
  //   //  const labels = this.orderUpdate.label;
  //     const labels = this.orderLabel;
  //     const hasLabel3 = labels.some(label => label.labelName === 'EXCL-FR-RPRT');
  //     const hasLabel4 = labels.some(label => label.labelName === 'INCL-IN-RPRT');
  //     if (hasLabel3 && hasLabel4) {
  //       this.pushToast("Both EXCL-FR-RPRT and INCL-IN-RPRT labels are added,Both are not allowed","","error");
  //       return;
  //     }
  // }
    this.orderUpdate.trackingId = "";
    if (form.orderTrackIds.length > 0) {
      form.orderTrackIds.forEach((id) => {
        this.orderUpdate.trackingId += id.code.toString();
      });
    } else {
      this.orderUpdate.trackingId = this.order.trackingId;
    }
    this.isUpdatingOrder = true;
    this.orderService.updateOrder(this.order.orderId, this.orderUpdate).subscribe(
      (response: OrderResponse) => {
        if (response.acknowledgement.status === "SUCCESS") {
          if (this.updateOrderForm.get('orderStatus').value != '' && this.updateStatus == true) {
            let orderUpdateStatus : OrderUpdateRequest = {} as OrderUpdateRequest;
            orderUpdateStatus.action = this.updateOrderForm.get('orderStatus').value;
            orderUpdateStatus.rule = this.rule
            this.orderService.updateOrder(this.order.orderId, orderUpdateStatus).subscribe((data: OrderResponse) => {
              if (data.acknowledgement.status === "SUCCESS") {
                 this.pushToast("Order Updated", "Details for order " + this.order.orderDisplayName + " changed", "success");
                 this.modelRef.hide();
              } else {
                this.pushToast("Status Change Failed", this.order.orderDisplayName + " => " + data.acknowledgement.message, "error")
              }
            },
              (error: HttpErrorResponse) => {
                this.pushToast("Status Change Failed", "", "error")
              })
          }
          else{
            this.modelRef.hide();
          }
          if (this.mailNotificationFlag) {
            this.notifyCustomer()
          }
          this.isUpdatingOrder = false;
        } else {
          this.isUpdatingOrder = false;
          this.pushToast(
            "Order Update Failed",
            response.acknowledgement.message + "for order :" + this.order.orderDisplayName,
            "error"
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.isUpdatingOrder = false;
        this.pushToast("Order Update Failed", error.error.acknowledgement.message, "error");
      }
    );
  }

  ruleCheck(){
    this.rule = !this.rule;
  }

  public printOrder() {
    this.isPrintingOrder = true;
    const orderId = {
      orderIds: [this.order.orderId],
    };
    this.orderService.generateDeliveryLabelsv2(orderId).subscribe(
      (response: GenerateLabelsResponse) => {
        this.isPrintingOrder = false;
        if (response.acknowledgement.status === "SUCCESS") {
          this.orderService.downloadDeliveryLabelsv2(response.data.id);
        } else {
          this.pushToast(
            "Order Print Failed",
            response.acknowledgement.message + "for order :" + this.order.orderDisplayName,
            "error"
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.isPrintingOrder = false;
        this.pushToast("Order Printing Failed", error.error.acknowledgement.message, "error");
      }
    );
  }

  public printLineItem(id: number) {
    this.isPrintingLineItem = id;
    const lineItemId = {
      lineItemIds: [id],
    };
    this.orderService.generateItemLabelsv2(lineItemId).subscribe(
      (response: GenerateLabelsResponse) => {
        this.isPrintingLineItem = -1;
        if (response.acknowledgement.status === "SUCCESS") {
          this.orderService.downloadItemLabelsv2(response.data.id);
        } else {
          this.pushToast("Item Print Failed", response.acknowledgement.message + "for itemId :" + id, "error");
        }
      },
      (error: HttpErrorResponse) => {
        this.isPrintingLineItem = id;
        this.pushToast("Item Printing Failed", error.error.acknowledgement.message, "error");
      }
    );
  }

  public pushToast(heading, text, icon) {
    const config = { closeButton: true, timeOut: 3000, positionClass: "toast-top-right", extendedTimeOut: 5000 };
    if (icon === "success") {
      this.toast.success(text, heading, config);
    }
    if (icon === "error") {
      this.toast.error(text, heading, config);
    }
    if (icon === "warning") {
      this.toast.warning(text, heading, config);
    }
    if (icon === "info") {
      this.toast.info(text, heading, config);
    }
  }
  public onTrackURLSelect(link) {
    if (link != null && link !== "") {
      window.open(link, "_blank");
    }
  }
  public onLabelSelected(event: TypeaheadMatch) {
    let index = this.orderLabel.map((item) => item.labelId).indexOf(event.item.labelId);
    if (index < 0) {
      this.orderLabel.push({
        labelId: event.item.labelId,
        labelColour: event.item.colour,
        labelName: event.item.labelName,
        colour: event.item.colour,
      });
    }
    this.updateOrderForm.get("allLabelsList").setValue("");
  }
  public onLabelClosed(lId) {
    let index = this.orderLabel.map((item) => item.labelId).indexOf(lId);
    //this.allLabels.push(this.orderLabel[index])
    this.orderLabel.splice(index, 1);
  }
  public subTotalPrice(qty: string, price: number) {
    return (Number(qty) * price);
  }
  EditlineItem() {
    this.editflag = false;
    // const initialState = {
    //   order: this.order
    // };
    // this.modalService.show(LineItemEditComponent,{initialState, animated:true,keyboard:true,class:'modal-lg modal-dialog-centered'})
  }

  notifyCustomer() {
    this.orderService.mailnotification(this.order.orderId).subscribe(res => {
      if (res.status == 'SUCCESS') {
        this.pushToast("Notification Updated", res.message, "success");
      }
      else {
        this.pushToast("Notification Update Failed", res.message, "error");
      }
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.pushToast("Unexpected Error!", "Notifying Customer Failed.", "error");
    })
  }

  viewCustomerOrders(customer){
    window.open(`/manage-orders/search?custRef=${customer.email}`, "_blank");
  }

  public exportAsPdf(id: string,displayname:string) {
    this.orderService.exportToPdf(id).subscribe({
      next: (response) => {
        this.downLoadFile(response, "application/pdf",'order_'+displayname)
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string,fileName:string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let anchor = document.createElement('a')
    anchor.href = url
    anchor.download = fileName
    anchor.click();
  }

}

