import { Component, OnInit } from '@angular/core';
import { OmsAdminManageServices } from '../../services/services';

@Component({
  selector: 'app-manage-tenants',
  templateUrl: './manage-tenants.component.html',
  styleUrls: ['./manage-tenants.component.scss']
})
export class ManageTenantsComponent implements OnInit {
  TableRolesloading=true;
  tenants = []
  constructor(private Services:OmsAdminManageServices,) { }

  ngOnInit(): void {
    this.FetchTanents()
  }

  /**
   * Getting all Tanents for table
   */
   FetchTanents(){
    this.TableRolesloading = true
    this.Services.getTenants(50,0).subscribe(res=>{
      if(res.acknowledgement.status=="SUCCESS"){
        this.tenants = res.tenants;
      }
      this.TableRolesloading = false;
      console.log(res)
    })
  }
}
