import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { MasterComponent } from './master/master.component';


const routes: Routes = [{
  path:'',
  canActivate:[AuthGuard],
  component:MasterComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OmsAdminRoutingModule { }