import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
  }


  /**
   * Redirect to UMS for getting valid token 
   */
   SignIn() {
    this.commonService.omsAccountsRedirection(window.location.href.replace('home','accounts'), 200);
  }

  /**
   * Redirect to UMS to create a user Account
   */
  SignUp() {
    var httpStatusCode: number = 200;
    this.commonService.umsSignup(window.location.href.replace('home','accounts'), httpStatusCode);
  }

}
