import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router'
import { CommonService } from '../services/common.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private service: CommonService, private router: Router) { }
  
  /**
   * Check the user whether authenticate to use the components or not
   * @param route ActivatedRouteSnapshot
   * @returns boolean
   */
  canActivate( route: ActivatedRouteSnapshot): boolean {
      if(route.queryParams.token && route.queryParams.token !== null ){
        let token = route.queryParams.token;
        localStorage.setItem("token", `Bearer ${token}`)
      }
      if(this.service.validateLogin()){
        return true;
      }
      else{
        this.router.navigate(['/home']);
        return false;
      }
  }
}
