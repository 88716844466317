import { PipeTransform, Pipe } from '@angular/core'
import { DropDownList } from "../models/util.model"
import { Note, Group, Label } from "../models/services-response/order-response.model"
import { transformAll } from '@angular/compiler/src/render3/r3_ast';

@Pipe({ name: 'orderLabelPipe', pure: false })
export class OrderLabelPipe implements PipeTransform {
    labelData: DropDownList[] = []
    transform(labels: Label[]) {
        if (labels) {
            this.labelData = []
            labels.forEach(element => {
                this.labelData.push({ code: element.labelId, text: element.labelName })
            })
        }
        return this.labelData
    }
}

@Pipe({ name: 'orderGroupPipe', pure: false })
export class OrderGroupPipe implements PipeTransform {
    groupData: DropDownList[] = []
    transform(group: Group) {
        this.groupData = []
        group ? this.groupData.push({ code: group.groupId, text: group.groupName }) : this.groupData = []
        return this.groupData
    }
}
@Pipe({ name: 'orderDeliveryChannelPipe', pure: false })
export class OrderDeliveryChannelPipe implements PipeTransform {
    channelData: DropDownList[] = []
    transform(channelId, channelName) {
        this.channelData = []
        channelId ? this.channelData.push({ code: channelId, text: channelName }) : this.channelData = []
        return this.channelData
    }
}
@Pipe({ name: 'orderTrackIdsPipe', pure: false })
export class OrderTrackIdsPipe implements PipeTransform {
    trackIds: DropDownList[] = []
    trackIdsArray: Array<string>
    transform(trackIds: string) {
        if (trackIds !== "" && trackIds !== null) {
            this.trackIds = []
            this.trackIdsArray = []
            this.trackIdsArray = trackIds.split(",")
            this.trackIdsArray.forEach(id => {
                this.trackIds.push({ code: id, text: id })
            })
            return this.trackIds
        } else {
            return ""
        }
    }
}
