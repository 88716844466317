import { Component, OnInit } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'order-fulfillment-front-end'

  constructor(private router: Router) {
  
  }
  ngOnInit() {
  }
}
