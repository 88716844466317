import { PipeTransform, Pipe } from '@angular/core'
import { Order } from '../models/services-response/order-response.model';
@Pipe({ name: 'statusIconPipe', pure: true })
export class StatusIconPipe implements PipeTransform {
  statusIconClasses = {
    C: "mdi mdi-backspace-outline",
    R: "mdi mdi-cancel",
    AP: "mdi mdi-check-all",
    PA: "mdi mdi-package-variant-closed",
    AC: "mdi mdi-check-all", // mdi mdi-check
    SH: "mdi mdi-truck-delivery",
    DD: "mdi mdi-home-map-marker",
    DP: "mdi mdi-clock-fast text-warning mdi-24px",
    DF: "mdi mdi-close-network text-danger mdi-24px",
    IP: "mdi mdi-package-variant-closed",
    RF: "mdi mdi-refresh"


  }
  transform(code) {
    return this.statusIconClasses[code]
  }
}
@Pipe({ name: 'statusIconPipeMod', pure: true })
export class StatusIconPipeMod implements PipeTransform {
  statusIconClasses = {
    C: "mdi text-warning mdi-backspace-outline mdi-24px",
    R: "mdi text-danger mdi-cancel mdi-24px",
    AP: "mdi text-primary mdi-check-all mdi-24px",
    PA: "mdi text-success mdi-package-variant-closed mdi-24px",
    AC: "mdi text-primary mdi-check-all mdi-24px", // mdi mdi-check
    SH: "mdi text-success mdi-truck-delivery mdi-24px",
    DD: "mdi text-success mdi-home-map-marker mdi-24px",
    DP: "mdi mdi-clock-fast text-warning mdi-24px",
    DF: "mdi mdi-close-network text-danger mdi-24px",
    IP: "mdi text-success mdi-package-variant-closed mdi-24px",
    RF: "mdi text-success mdi-refresh mdi-24px"


  }
  transform(code) {
    return this.statusIconClasses[code]
  }
}

@Pipe({ name: "statusButtonPipe", pure: true })
export class StatusButtonPipe implements PipeTransform {
  statusButtonClasses = {
    C: "btn btn-outline-warning btn-icon btn-sm ",
    R: "btn btn-outline-danger btn-icon btn-sm",
    AP: "btn btn-outline-primary btn-icon btn-sm",
    PA: "btn btn-outline-success btn-icon btn-sm",
    AC: "btn btn-outline-primary btn-icon btn-sm", // "btn btn-info btn-icon btn-sm",
    SH: "btn btn-outline-success btn-icon btn-sm",
    DD: "btn btn-outline-success btn-icon btn-sm",
    DP: "mdi mdi-clock-fast text-warning mdi-24px",
    DF: "mdi mdi-close-network text-danger mdi-24px",
    RF: "btn btn-outline-success btn-icon btn-sm",
    IP: "btn btn-outline-success btn-icon btn-sm"
  }
  transform(code) {
    return this.statusButtonClasses[code]
  }
}
@Pipe({ name: "rowHighlightPipe" })
export class RowHighlightPipe implements PipeTransform {
  styleBg = {
    // "background-color" : "#FDD835"
    "color": "#ff9800"
  }
  transform(order: Order) {
    // order.deliveryDate == null || order.deliveryDate === "" ||
    //     order.shippingAddress == null || order.shippingAddress.name === "" ||
    if (order.fulfillmentStatus.currentStatusCode === "SU") {
      return this.styleBg
    }
    return ""
  }
}

@Pipe({ name: "orderTypeColorPipe" })
export class OrderTypeColorPipe implements PipeTransform {
  colorCodes = {
    blue: { "color": "#2196F3" },
    green: { "color": "#4CAF50" },
    pink: { "color": "#F48FB1" },
    purple: { "color": "#9C27B0" },
    grey: { "color": "#607D8B" },
    pink_dark: { "color": "#E91E63" }
  }
  transform(orderType: String) {
    switch (orderType.toLowerCase()) {
      case "type 1": return this.colorCodes.blue
      case "type 2": return this.colorCodes.green
      case "type 3": return this.colorCodes.pink
      case "type 4": return this.colorCodes.purple
      case "type 5": return this.colorCodes.grey
      case "type 6": return this.colorCodes.pink
      case "type 7": return this.colorCodes.pink_dark
    }
  }
}

@Pipe({ name: "currentStatusPipe" })
export class CurrentStatusPipe implements PipeTransform {
  iconClasses = {
    SU: "mdi mdi-information-outline text-warning mdi-24px", //  "mdi mdi-check text-warning mdi-24px",
    C: "mdi mdi-backspace-outline text-warning mdi-24px",
    R: "mdi mdi-cancel text-danger mdi-24px",
    AP: "mdi mdi-check-all text-primary mdi-24px",
    PA: "mdi mdi-package-variant-closed text-success mdi-24px",
    AC: "mdi mdi-check-all text-primary mdi-24px",
    SH: "mdi mdi-truck-delivery text-success mdi-24px",
    DD: "mdi mdi-home-map-marker text-success mdi-24px",
    DP: "mdi mdi-clock-fast text-warning mdi-24px",
    DF: "mdi mdi-close-network text-danger mdi-24px",
    'DELIVERY FAILED': "mdi mdi-close-circle-outline text-danger mdi-24px",
    'DELIVERY POSTPONED': "mdi mdi-clock-fast text-warning mdi-24px",
    IP: "mdi mdi-cogs text-primary mdi-24px",
    DELIVERED: "mdi mdi-home-map-marker text-success mdi-24px",
    DELETED: "mdi mdi-delete-outline text-danger mdi-24px",
    FAILED: "mdi mdi-alpha-f-circle",
    SHIPPED: "mdi mdi-truck-delivery text-success mdi-24px",
    ASSIGNED: "mdi mdi-account-star text-primary mdi-24px",
    ERROR: "mdi mdi-alert-octagram text-danger mdi-24px",
    SUCCESS: "mdi mdi-checkbox-marked-circle-outline text-primary mdi-24px",
    DELETE_ERROR: "mdi mdi-close-circle-outline text-danger mdi-24px"
  }
  transform(status) {
    return this.iconClasses[status]
  }
}
// @Pipe({ name: "routeHighlightPipe" })
// export class RouteHighlightPipe implements PipeTransform {
//     constructor ( private router: Router){

//     }

//     highlight: { "color:white" }
//     transform(path) {
//         if (path === this.router.url) {
//             return this.highlight
//         }
//     }
// }
