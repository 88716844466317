import { Component, Injectable, OnInit } from '@angular/core'
import { Menu } from './navigation.model'
import { CommonService } from "../../../services/common.service"
import { Router } from '@angular/router'
import { User } from "../../../models/services-response/login-response.model"
import { Features } from 'src/app/shared/models/services-response/features.model'
import { environment } from 'src/environments/environment'
import { HttpErrorResponse } from '@angular/common/http'
import { Tenants } from 'src/app/shared/models/services-response/get-multi-tanent-response.model'
import { Subject } from 'rxjs'
import { MessageService } from 'src/app/shared/utils/messageService'


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

@Injectable({providedIn: 'root'})
export class NavigationComponent implements OnInit {
  expand:boolean = true;
  features: Features
  menuItemsAll = {
    DASHBOARD: {
      featureName: 'Dashboard',
      name: 'Dashboard',
      icon: 'view-dashboard-variant',
      path: '/dashboard',
      tooltip: '',
    },
    MANAGE_ORDERS: {
      featureName: '',
      name: 'Manage Orders',
      icon: 'cart',
      path: '#',
      tooltip: '',
      submenu: [

      ]
    },
    INVENTORY: {
      featureName: '',
      name: 'Inventory',
      icon: 'store',
      path: '/inventory/sku-summary',
      tooltip: '',
      // submenu: [

      // ]
    },
    REPORTING: {
      featureName: '',
      name: 'Reporting',
      icon: 'file-compare',
      path: '/data-process',
      tooltip: '',
    },
    SHIPMENT: {
      featureName: '',
      name: 'Shipment',
      icon: 'truck-fast',
      path: '#',
      tooltip: '',
      submenu: [

      ]
    },
    ADMINISTRATION: {
      featureName: '',
      name: 'Administration',
      icon: 'settings',
      path: '#',
      tooltip: '',
      submenu: [

      ]
    }
  }
  submenuItemsALL = {
    MANAGE_ORDERS: {
      LISTING: { name: 'Orders Listing', icon: '', path: '/manage-orders/listing', tooltip: '', featureName: '' },
      SEARCH: { name: 'Find Orders', icon: '', path: '/manage-orders/search', tooltip: '', featureName: '' },
      ERROR: { name: 'Error Orders', icon: '', path: '/manage-orders/errors', tooltip: '', featureName: '' },
      CREATE: { name: 'Create Orders', icon: '', path: '/manage-orders/create', tooltip: '', featureName: '' },
      SALES: { name: 'Sales Orders', icon: '', path: '/manage-orders/sales-orders', tooltip: '', featureName: '' },
    },
    INVENTORY: {
      SKU: { name: 'SKU Summary', icon: '', path: '/inventory/sku-summary', tooltip: '', featureName: '' },
    },
    SHIPMENT: {
      BATCH_PROCESS: { name: 'Batch Process', icon: '', path: '/shipment/batch-process', tooltip: '' },
      ERROR: { name: 'Shipping Errors', icon: '', path: '/shipment/errors', tooltip: '' },
    },
    ADMINISTRATION: {
      SYS_CONFIG: { name: 'System Configuration', icon: '', path: '/configurations', tooltip: '', featureName: '', },
      ZIP_MAPPING: { name: 'Zipcode Mapping', icon: '', path: '/admin-tools/zip-mapping', tooltip: '', featureName: '', },
      ORDER_LABELS: { name: 'Manage Order Labels', icon: '', path: '/admin-tools/manage-labels', tooltip: '', featureName: '', },
      MANAGE_USERS: { name: 'Manage Users', icon: '', path: '/admin-tools/manage-users', tooltip: '', featureName: '', },
      STATUS_CONFIG: { name: 'Status Configurations', icon: '', path: '/admin-tools/status-config', tooltip: '', featureName: '', },
      MANAGE_NOTIF: {
        featureName: '',
        name: 'Manage Notifications',
        icon: '',
        path: '#',
        tooltip: '',
        submenu: [
          { name: 'One-Time', icon: '', path: '/admin-tools/notification-config/one-time', tooltip: '', featureName: '', },
          { name: 'Triggered', icon: '', path: '/admin-tools/notification-config/triggered', tooltip: '', featureName: '', }
        ]
      },
      MANAGE_CHANNELS: {
        featureName: '',
        name: 'Manage Channels', icon: '',
        path: '#',
        tooltip: '',
        submenu: [
          { name: 'Sales', icon: '', path: '/admin-tools/manage-channels/sales', tooltip: '', featureName: '', },
          { name: 'Delivery', icon: '', path: '/admin-tools/manage-channels/delivery', tooltip: '', featureName: '', },
        ]
      },
    }
  }

  menuItems: Array<Menu> = []
  user: User
  $: any
  isProduction = true
  toast: any
  tenant: Tenants
  constructor(private srvcs: CommonService, private router: Router,private messageService: MessageService) { }

  ngOnInit() {
    this.isProduction = this.srvcs.isProduction()
    this.getUserDetails()
    // Once the getFeatures service back online please remove the part ?? {...}
    this.features = JSON.parse(this.srvcs.getFeatures())??{
      dashboard:{
        ops_summary:true,
        order_reports:true
      },
      configuration:{
        order_label:true,
        sys_config:true,
        zip_code_maping:true
      },
      Inventory:{
        sku:true
      },
      orders:{
        create:true,
        error:true,
        listing:true,
        search:true
      },
      reporting:null,
      shipment:null
    }
    this.menuItems = []
    this.setNavBarItems()


  }
  public setNavBarItems() {
    // this.features.dashboard ? this.menuItems.push(this.menuItemsAll.DASHBOARD) : null
    this.features.orders ? this.setOrdersNav(this.features.orders) : null
    // this.features.Inventory.sku ? this.menuItems.push(this.menuItemsAll.INVENTORY) : null
    // this.features.Inventory ? this.setInventoryNav(this.features.Inventory) : null
    this.features.reporting ? this.menuItems.push(this.menuItemsAll.REPORTING) : null
    this.features.shipment ? this.setShipmentNav(this.features.shipment) : null
    this.features.configuration ? this.setConfigNav(this.features.configuration) : null
  }

  // setInventoryNav(menu) {
  //   if(menu.sku){
  //     this.menuItemsAll.INVENTORY.submenu.push(this.submenuItemsALL.INVENTORY.SKU)
  //   }
  //   this.menuItems.push(this.menuItemsAll.INVENTORY)
  // }

  setOrdersNav(menu) {
    if (menu.listing) {
      this.menuItemsAll.MANAGE_ORDERS.submenu.push(this.submenuItemsALL.MANAGE_ORDERS.LISTING)
    }
    if (menu.search) {
      this.menuItemsAll.MANAGE_ORDERS.submenu.push(this.submenuItemsALL.MANAGE_ORDERS.SEARCH)
    }
    // if (menu.error) {
    //   this.menuItemsAll.MANAGE_ORDERS.submenu.push(this.submenuItemsALL.MANAGE_ORDERS.ERROR)
    // }
    if (menu.create) {
      this.menuItemsAll.MANAGE_ORDERS.submenu.push(this.submenuItemsALL.MANAGE_ORDERS.CREATE)
    }
    if (menu.sales_orders) {
      this.menuItemsAll.MANAGE_ORDERS.submenu.push(this.submenuItemsALL.MANAGE_ORDERS.SALES)
    }
    this.menuItems.push(this.menuItemsAll.MANAGE_ORDERS)
  }
  setShipmentNav(menu) {
    if (menu.batch_process) {
      this.menuItemsAll.SHIPMENT.submenu.push(this.submenuItemsALL.SHIPMENT.BATCH_PROCESS)
    }
    if (menu.error) {
      this.menuItemsAll.SHIPMENT.submenu.push(this.submenuItemsALL.SHIPMENT.ERROR)
    }
    this.menuItems.push(this.menuItemsAll.SHIPMENT)
  }
  setConfigNav(menu) {
    // if (menu.zip_code_maping) {
    //   this.menuItemsAll.ADMINISTRATION.submenu.push(this.submenuItemsALL.ADMINISTRATION.ZIP_MAPPING)
    // }
    if (menu.sys_config) {
      this.menuItemsAll.ADMINISTRATION.submenu.push(this.submenuItemsALL.ADMINISTRATION.SYS_CONFIG)
    }
    if (menu.order_label) {
      this.menuItemsAll.ADMINISTRATION.submenu.push(this.submenuItemsALL.ADMINISTRATION.ORDER_LABELS)
    }
    this.menuItems.push(this.menuItemsAll.ADMINISTRATION)
    this.menuItemsAll.ADMINISTRATION.submenu.push(this.submenuItemsALL.ADMINISTRATION.MANAGE_USERS)
  }
  public getUserDetails() {
    this.user = this.srvcs.getUserDetails()
    this.tenant = JSON.parse(localStorage.getItem('Activetenant'))
  }
  public logout() {
    /*this.srvcs.deleteToken().subscribe((res) => {
      //this.srvcs.clearNotification();
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.toast.error("Error occured while removing cloud message token from server", "Error deleting Cloud message token.")
    })*/
    this.srvcs.signOut()
    this.srvcs.removeUserDetails()
    var httpStatusCode: number = 200;
    this.srvcs.omsAccountsRedirection(`${environment.origin}/accounts`, httpStatusCode);
    //this.router.navigate(['/sign-in'])
  }

  SwitchAccount(){
    localStorage.removeItem('X-OMS-ACCESS-TOKEN')
    this.router.navigate(['/accounts'])
  }

  isFeatureEnabled(fname) {

  }

  expandMenu(){
    this.messageService.sendMessage(JSON.stringify({sidemenu:this.expand?'false':'true'}));
    this.expand = !this.expand
  }
}
