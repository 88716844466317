
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { CommonService } from '../services/common.service'

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService, public router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * Attaching neccessory headers to the Httprequest
     */

    req = req.clone({
      setHeaders: {
        'Authorization': `${this.commonService.getToken()}`,
        'X-OMS-ACCESS-TOKEN': this.commonService.getXomsToken(),
        // 'fulfillmentCenterId': this.commonService.getFulfillmentCenter(),
        // 'vendorId': this.commonService.getVendor(),
        'Content-Type': "application/json",
      }
    })

    /**
     * Removing fulfillmentcenterid,vendorId and gsheet header if gsheet header
     * present in the request header
     */

    if (req.headers.get("gSheet") || req.headers.get("token") || req.headers.get('removeXoms')) {
      req = req.clone({ headers: req.headers.delete('fulfillmentcenterid').delete('vendorId').delete('gsheet') });

      /**
       * Removing X-OMS-ACCESS-TOKEN and removeXoms if the request contains removeXoms-Header
       */

      if (req.headers.get("removeXoms")) {
        req = req.clone({ headers: req.headers.set('Authorization', `${this.commonService.getToken()}`).delete('removeXoms').delete('X-OMS-ACCESS-TOKEN') });
      }
    }

    /**
     * Updating Both X-OMS-ACCESS-TOKEN and Authorization token if the responser header
     * contains both, if the request return 401 - navigate to home screen,
     */

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const Authorization = event.headers.get('Authorization')
          const XOMSACCESSTOKEN = event.headers.get('X-OMS-ACCESS-TOKEN')
          if (Authorization) {
            this.commonService.updateToken(Authorization)
          }
          if (XOMSACCESSTOKEN) {
            this.commonService.updateXomsToken(XOMSACCESSTOKEN)
          }
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (!err.url.endsWith(this.commonService.authPoint) && !err.url.endsWith('/users/login')) {
            if (err.status === 401) {
              this.commonService.signOut();
              this.router.navigate(['/home'])
            }
            else if (err.status === 503) {
              this.router.navigate(['/downtime'])
            }
          }
        }
      })
    )
  }
}



