import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
;
import { OmsAdminManageServices } from '../services/services';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {
  roleForm: FormGroup;
  Menu = [
    {
      title: "Manage Roles",
      disabled: false,
      active:true
    },
    {
      title:"Manage Tenants",
      disabled:false,
      active:true,
    }
  ]
  selectedTab = {
    title: "Manage Roles",
    disabled: false,
    active:true
  }
  roles = []
  access = []
  rolename= ''
  description=''
  selectedAccess:Array<any> = [];
  dropdownSettings:IDropdownSettings = {};
  TableRolesloading=false;
  modalRef: BsModalRef;
  CreateModalh1:string = 'Create Role';

  constructor(
    private Services:OmsAdminManageServices,
    public modalModule: ModalModule, 
    private modalService: BsModalService,
    public fb: FormBuilder,
    private toast: ToastrService,
    ) { }
  

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      id:'',
      name: ['', Validators.required],
      description:['',Validators.required],
      selectedAccess:[]
    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.FetchuserRoles()
    this.FetchAccessList()
  }

  /**
   * Getting all access list for Access dropdown
   */
  FetchAccessList(){
    this.Services.getAccess().subscribe(res=>{
      if(res.acknowledgement.status=="Success"){
        this.access = res.accesses;
      }
      console.log(res)
    })
  }

  /**
   * Getting all users with roles for table
   */
  FetchuserRoles(){
    this.TableRolesloading = true
    this.Services.getRoles().subscribe(res=>{
      if(res.acknowledgement.status=="SUCCESS"){
        this.roles = res.roles;
      }
      this.TableRolesloading = false;
      console.log(res.roles)
    })
  }

  /**
   * Show modal for updating Role, assigning form values and 
   * dropdown values to form elements
   * @param template TemplateRef
   * @param role roleDate for Edit
   */
  ShowAccessmodal(template: TemplateRef<any>,role) {
    this.CreateModalh1 = 'Edit Role';
    this.roleForm.get('id').setValue(role.id)
    this.roleForm.get('name').setValue(role.name)
    this.roleForm.get('description').setValue(role.description)
    this.selectedAccess = role.accesses;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered', })
  }

  /**
   * Updating Roles 
   */
  UpdateRole(form:FormGroup){
    if(this.selectedAccess.length>0){
      let RequestBody = {
        access:this.selectedAccess.map(e=>{return e.id}),
        id:form.value.id,
        description:form.value.description,
        name:form.value.name
      }
      this.Services.updateUserRole(RequestBody).subscribe(res=>{
        if(res.status=="SUCCESS"){
          this.toast.success(res.message,this.CreateModalh1)
          this.FetchuserRoles()
        }
        else{
          this.toast.error(res.message,'Update Role')
        }
      })
    }
    else{
      this.toast.warning('Please select any role to proceed!',this.CreateModalh1)
    }
  }

  /**
   * Trigger the modal for Creating Roles
   */
  ShowAccessmodalCreate(template:TemplateRef<any>){
    this.CreateModalh1 = 'Create Role';
    this.roleForm.get('id').setValue(null)
    this.selectedAccess = [];
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered', })
  }

  onAccessSelectAll(items:Array<any>){
    this.roleForm.patchValue({selectedAccess:items})
  }

  onAccessSelect(item:Array<any>){
    console.log(item)
  }

  MenuChange(menu:any){
    this.selectedTab = menu
  }
}
