import { Injectable } from '@angular/core'
import { Auth } from "../models/service-request/auth-request.model"
import { environment } from '../../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { UsersResponse, User, Role } from "../models/services-response/login-response.model"
import { BehaviorSubject, Observable } from 'rxjs';
import { Acknowledgement } from '../models/services-response/acknowledgement-response.model';
import { FeaturesResponse, Features } from '../models/services-response/features.model'
import { param } from 'jquery'
import { CustomerResponse } from '../models/services-response/customer-response.mode'
import { SystemConfigsResponse } from '../models/services-response/system-configs-response.model'
import { GetUserRoleResponse } from '../models/services-response/get-user-role-response.model'
import { UsersDetailFetchResponse } from '../models/services-response/users-detail-fetch-response.model'
import { UpdateUserRequest } from '../models/service-request/update-user-request.model'
import { UserDetailRequest } from '../models/service-request/user-creation-request.model'
import { CreateUserResponse } from '../models/services-response/user-creation-response.model'
import { GetMultitanentListResponse } from '../models/services-response/get-multi-tanent-response.model'
import { tenantInitRequest } from '../models/service-request/tenantInit-request.model'
import { GenerateInviteCodeRequest } from '../models/service-request/GenerateInviteCodeRequest.model'
import { GenerateInviteCodeResponse } from '../models/services-response/GenerateInviteCodeResponse.model'
import { JwtHelperService } from '@auth0/angular-jwt'
import { GetAllUserOfTenantsResponse } from '../models/services-response/get-all-users-oftenants.model'
import { UpdateUserRoleResponse } from '../models/services-response/response.model'
import { bulkupdateRequestModel } from '../models/service-request'
import { bulkupdateResponseModel } from '../models/services-response'
import { BulkMessagePayload } from '../models/service-request/bulkmessage-request.model'
import { BulkMessageResponse } from '../models/services-response/BulkMessageResponse'
import {  HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  authPoint = "/orders"
  private APIEndpoint = environment.APIEndpoint
  private UMSEndpoint = environment.APIEndpointUMS
  private SalesConnectorEndpoint = environment.SalesConnectorEndpoint
  private features: Features = null
  constructor(public http: HttpClient,public JwtHelperService:JwtHelperService) {
  }
  public validateLogin(): boolean {
    let storageToken = localStorage.getItem('token')
    if (storageToken && storageToken != "") {
      return true
    }
    return false
  }
  private updateTokenOnLocalStorage(token: string) {
    localStorage.setItem('token', token)
  }

  public IsServerUp() {
    return this.http.get(`${this.APIEndpoint}/health/database`)
  }

  public setUserDetails() {
    let token = localStorage.getItem("token");
    let Decodedtoken = this.JwtHelperService.decodeToken(token)
    if(Decodedtoken)
    localStorage.setItem('username', Decodedtoken.username)
    // localStorage.setItem('userrole', role.roleDescription)
    return 0
  }
  public removeUserDetails() {
    localStorage.removeItem('username')
    localStorage.removeItem('userrole')
  }
  public getUserDetails() {
    let user = {} as User;
    let role = {} as Role;
    role.roleDescription = localStorage.getItem('userrole');
    user.userName = localStorage.getItem('username');
    user.role = role;
    return user
  }
  private removeTokenOnLocalStorage() {
    localStorage.removeItem('token')
    localStorage.removeItem('X-OMS-ACCESS-TOKEN')
  }

  public getToken(): string | null {
    let token = ''
    if (localStorage.hasOwnProperty('token')) {
      token = localStorage.getItem('token')
    }
    return token
  }

  public getXomsToken(): string | null {
    let token = ''
    if (localStorage.hasOwnProperty('X-OMS-ACCESS-TOKEN')) {
      token = localStorage.getItem('X-OMS-ACCESS-TOKEN')
    }
    return token
  }

  public getFulfillmentCenter(): string | null {
    let ffc = ''
    if (localStorage.hasOwnProperty('ffc_id')) {
      ffc = localStorage.getItem('ffc_id')
    }
    return ffc
  }
  public getVendor(): string | null {
    let vend = ''
    if (localStorage.hasOwnProperty('vend_id')) {
      vend = localStorage.getItem('vend_id')
    }
    return vend
  }

  /*public deleteToken() {
    let tokenVar = localStorage.getItem("CloudMessageToken") ? localStorage.getItem("CloudMessageToken") : null
    let deleteTokenRequest = {
      "token": tokenVar
    }
    return this.http.post<TokenResponse>(`${this.APIEndpoint}/user/delete/cloud-messaging`, deleteTokenRequest)
  }*/

  public updateToken(token: string) {
    this.updateTokenOnLocalStorage(token)
  }
  public updateXomsToken(token:string){
    localStorage.setItem('X-OMS-ACCESS-TOKEN',token)
  }
  public signOut() {
    this.removeTokenOnLocalStorage()
    this.removeFeatures()

  }
  private removeFeatures() {
    this.features = null
    localStorage.removeItem("features")
  }
  public signIn(auth: Auth) {
    return this.http.post<UsersResponse>(`${this.APIEndpoint}/users/login`, auth)
  }

  public getUsers(): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(`${this.APIEndpoint}/users`);
  }

  public getDeliveryUsers(): Observable<UsersResponse> {
    return this.http.get<any>(`${this.APIEndpoint}/master/users/delivery`);
  }

  public isProduction() {
    return environment.production
  }
  public getFeatures() {
    return this.features !== null ? this.features : JSON.parse(localStorage.getItem('features'))
  }
  public setFeatures(features) {
    this.features = features
    localStorage.setItem('features', JSON.stringify(this.features))
  }

  public fetchFeatures() {
    return this.http.get<FeaturesResponse>(`${this.APIEndpoint}/features`)
  }

  public getCustomers(searchBy, search, limit, offset) {
    let httpParams = new HttpParams()
    httpParams = httpParams.append("searchBy", searchBy)
      .append("search", search)
      .append("limit", limit)
      .append("offset", offset)
    return this.http.get<CustomerResponse>(`${this.APIEndpoint}/customers`, { params: httpParams })

  }

  public getSystemConfiguration(configs: { configKey: string }) {
    let httpParams = new HttpParams()
    for (const config in configs) {
      httpParams = httpParams.append(config, configs[config])
    }
    return this.http.get<SystemConfigsResponse>(`${this.APIEndpoint}/system_configurations`, { params: httpParams })
  }

  public setSystemConfiguration(configKey:string,configValues:string[]) {
    let data = {
      config:[
        {
          configKey : configKey,
          configValues : configValues
        }
      ]
    }
    return this.http.post(`${this.APIEndpoint}/system_configurations`, data)
  }


  // user management

  public getUserRole() {
    return this.http.get<GetUserRoleResponse>(`${this.APIEndpoint}/master/roles`)
  }


  public viewUserDetails(role, status) {
    let httpParams = new HttpParams()
    if (role) {
      httpParams = httpParams.append("roleId", role.toString())
    }
    if (status) {
      httpParams = httpParams.append("status", status.toString())
    }
    return this.http.get<UsersDetailFetchResponse>(`${this.APIEndpoint}/users?`, { params: httpParams })
  }

  public viewUser(userId: string) {
    return this.http.get<UsersDetailFetchResponse>(`${this.APIEndpoint}/users/${userId}`)
  }

  public updateUser(userId, updateUser: UpdateUserRequest) {
    return this.http.post<UsersDetailFetchResponse>(`${this.APIEndpoint}/users/${userId}`, updateUser)
  }

  public createUser(userDetailRequest: UserDetailRequest) {
    return this.http.post<CreateUserResponse>(`${this.APIEndpoint}/users/create`, userDetailRequest)
  }

  public omsRedirection(redirectionLink: string, redirectCount: string) {
    var redirectDetails = encodeURIComponent(redirectionLink);
    window.open(this.UMSEndpoint + `/auth?redirect=${redirectDetails}&count=${redirectCount}&app=oms`, "_self")
  }

  public umsSignup(redirectionLink: string, statusCode: number){
    var redirectDetails = encodeURIComponent(redirectionLink);
    window.open(this.UMSEndpoint + `/sign-up?redirect=${redirectDetails}&app=oms&status=${statusCode}`, "_self")
  }

  public omsAccountsRedirection(redirectionLink: string, statusCode: number) {
    var redirectDetails = encodeURIComponent(redirectionLink);
    console.log(redirectDetails)
    window.open(this.UMSEndpoint + `/accounts?redirect=${redirectDetails}&app=oms&status=${statusCode}`, "_self")
  }

  public getUserAccounts(){
    return this.http.get<GetMultitanentListResponse>(`${this.APIEndpoint}/master/account/list`,{
      headers:{ "removeXoms":"true" }
    })
  }

  public tenantAccess(id:string){
    return this.http.get<Acknowledgement>(`${this.APIEndpoint}/master/account/${id}/access`,{headers:{ "removeXoms":"true" }})
  }

  public tenantInit(tenantInitRequest:tenantInitRequest){
    return this.http.post(`${this.APIEndpoint}/master/tenant/init`,tenantInitRequest,{headers:{ "removeXoms":"true" }})
  }

  public GenerateInviteCode(GenerateInviteCodeRequest:GenerateInviteCodeRequest){
    return this.http.post<GenerateInviteCodeResponse>(`${this.APIEndpoint}/master/user/invite`,GenerateInviteCodeRequest)
  }

  public VerifyInviteCode(code:string){
    return this.http.get<Acknowledgement>(`${this.APIEndpoint}/master/user/invite/${code}/accept`)
  }

  public GetAllUserOfTenants(offset:number,limit:number){
    return this.http.get<GetAllUserOfTenantsResponse>(`${this.APIEndpoint}/master/users?offset=${offset}&limit=${limit}`)
  }

  public DeactivateUser(userId:number){
    return this.http.get<Acknowledgement>(`${this.APIEndpoint}/master/user/${userId}/deactivate`)
  }

  public ActivateUser(userId:number){
    return this.http.get<Acknowledgement>(`${this.APIEndpoint}/master/user/${userId}/activate`)
  }

  public UpdateUserRole(userId:number,RequestBody:any){
    return this.http.post<UpdateUserRoleResponse>(`${this.APIEndpoint}/master/user/${userId}/role`,RequestBody)
  }

  public MassUpdate(payload:bulkupdateRequestModel){
    return this.http.post<bulkupdateResponseModel>(`${this.APIEndpoint}/orders/update/bulk`,payload)
  }

  public GetRecordStatusCount(limit,offset,orderStatus){
    return this.http.get(`${this.APIEndpoint}/orders?limit=${limit}&offset=${offset}&countOnly=true&orderStatus=${orderStatus}`)
  }
  
  public GetColumnOrder(){
    return this.http.get<any>(`${this.APIEndpoint}/feColumnChanges`)
  }

  BulkMessage(payload:BulkMessagePayload){
    return this.http.post<BulkMessageResponse>(`${this.APIEndpoint}/notification`,payload)
  }

  public amazonAuthorization(sellerPartnerId: string) {
   return this.http.get<any>(`${this.SalesConnectorEndpoint}/amazon/authorize/${sellerPartnerId}`)
  
  }


}
