import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name:'OmsCurrency',pure:true})
export class OmsCurrency implements PipeTransform {
  constructor() { }
  transform(value: string): string {
    try {
      if (value === "" || value === null) {
        return value
      }
      else{
        return getCurrencySymbol(value,"narrow");
      }
    } catch (error) {
      console.log(error)
      return value
    }

  }
}