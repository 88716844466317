import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { Ng2TelInputModule } from 'ng2-tel-input'
import { SignInComponent } from '../app/pages/sign-in/sign-in.component'
import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routing'
import { PagesComponent } from './pages/pages.component'
import { SharedModule } from './shared/shared.module'
import { AppInterceptor } from './shared/utils/app-network-interceptor';
import { HomeComponent } from './pages/home/home.component';
import { AccountsComponent } from './pages/accounts/accounts.component'
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt'
import { OmsAdminModule } from './oms-admin/oms-admin.module'
import { DataTablesModule } from 'angular-datatables';
import { SideMenuComponent } from './pages/side-menu/side-menu.component'
import { UiSwitchModule } from 'ngx-toggle-switch'

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SignInComponent,
    HomeComponent,
    AccountsComponent,
    SideMenuComponent,
  ],
  imports: [
    AppRoutingModule,
    OmsAdminModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Ng2TelInputModule.forRoot(),
    DataTablesModule.forRoot(),
    UiSwitchModule

  ],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },JwtHelperService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
