import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from "../../shared/models/service-request/auth-request.model";
import { UsersResponse } from '../../shared/models/services-response/login-response.model';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup
  authMessage: String
  loginFailed: Boolean = false
  signingIn: Boolean = false
  hiddenPassword :Boolean = true;
  constructor(public formBuilder: FormBuilder,
    public router: Router,
    public activateRoute: ActivatedRoute,
    public commonService: CommonService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      'username': "",
      'password': "",
    })
  }
  public onLoginFormSubmit(auth: Auth) {
    this.signingIn = true
    this.authMessage = ""
    this.commonService.signIn(auth).subscribe((data: UsersResponse) => {
      if (data.acknowledgement.status === "SUCCESS") {
        this.loginFailed = false
        // this.commonService.setUserDetails(data.users[0], data.users[0].role)
        this.commonService.fetchFeatures().subscribe((response) => {
          if (response.features) {
            this.commonService.setFeatures(JSON.stringify(response.features))
            this.router.navigate(['/manage-orders/search'])
          }
        })
      }
    }, (error: HttpErrorResponse) => {
      this.signingIn = false
      this.loginFailed = true
      if (error.status === 401) {
        this.authMessage = "*Incorrect Credentials! Try again"
      } else {
        this.authMessage = "Oops! Something went wrong"
      }
    }
    )
  }
}
