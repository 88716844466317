import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core'
import { Order } from '../models/services-response/order-response.model';
@Pipe({ name: 'totalNotesCount', pure: false })
export class TotalNotesCount implements PipeTransform {
    transform(order: Order) {
        const fulfillNotes: number = order.notes.length
        const userNotes: number = order.note ? 1 : 0
        const totalNotesCount = fulfillNotes + userNotes
        return totalNotesCount
    }

}

@Pipe({ name: 'omsDatePipe', pure: true })
export class OmsDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }
  transform(date: string | Date, format: string) {
    try {
      if (date === "" || date === null) {
        return "Not Set"
      }
      else {
        return this.datePipe.transform(date, format)
      }
    } catch (error) {
      return "Invalid Date"
    }
  }
}

@Pipe({ name: 'dateHandlePipe', pure: true })
export class DateHandlePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }
  transform(date: string) {
    try {
      if (date === "" || date === null) {
        return "Not Set"
      }
      else {
        return this.datePipe.transform(date, "dd/MM/yyyy")
      }
    } catch (error) {
      return "Invalid Date"
    }
  }
}

@Pipe({name:'dateRangeHandlePipe',pure:true})
export class DateRangeHandlePipe implements PipeTransform{
  constructor(private datePipe:DatePipe){}
  transform(value: string) {
    try {
      if(value ===''||value === null){
        return "Not Set"
      }
      else{
        console.log(value)
        return `${this.datePipe.transform(value[0], "dd/MM/yyyy")} to ${this.datePipe.transform(value[1], "dd/MM/yyyy") }`
      }
    } catch (error) {
      return "Invalid Date"
    }
  }
}

@Pipe({name:'dropdownArraytoString'})
export class DropdownArraytoStringPipe implements PipeTransform {
  transform(value: Array<any>) {
    try {
      return value.map(e=>{return e.text }).toString() 
    } catch (error) {
      return "Invalid"
    }
  }
}
