import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  subscription: Subscription;
  thisYear: number;

  constructor(private service:CommonService, private router: Router,private _location: Location) { }

  ngOnInit() {
    this.thisYear= (new Date()).getFullYear()
    this.isServerCheck();
    const source = interval(10000);
    this.subscription = source.subscribe(val => this.isServerCheck());
  }

  isServerCheck(){
    this.service.IsServerUp().subscribe(()=>{
      this._location.back();
    })
  }

  ngOnDestroy() {
    console.log("hi");
    this.subscription.unsubscribe();
  }

}
