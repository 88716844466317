import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OmsAdminRoutingModule } from './oms-admin-routing.module';
import { MasterComponent } from './master/master.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { ManageTenantsComponent } from './master/manage-tenants/manage-tenants.component';


@NgModule({
  declarations: [MasterComponent, ManageTenantsComponent],
  imports: [
    CommonModule,
    OmsAdminRoutingModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule.forRoot()
  ],
})
export class OmsAdminModule { }
