import { NgModule } from '@angular/core'
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'
import { SignInComponent } from "../app/pages/sign-in/sign-in.component"
import { AccountsComponent } from './pages/accounts/accounts.component'
import { HomeComponent } from './pages/home/home.component'
import { PagesComponent } from './pages/pages.component'
import { MaintenanceComponent, NotFoundComponent } from './shared/components/custom'
import { AuthGuard } from './shared/guards/auth.guard'

const isMaintenanceMode = false

const routes: Routes = [
  {
    path: '',
    component: isMaintenanceMode ? MaintenanceComponent : PagesComponent,
    children: [
      { path: '', redirectTo: '/manage-orders/search', pathMatch: 'full' },
      { path: 'data-process', loadChildren: () => import('./pages/data-process/data-process.module').then(m => m.DataProcessModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
      { path: 'shipment', loadChildren: () => import('./pages/shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [AuthGuard] },
      { path: 'manage-orders', loadChildren: () => import('./pages/manage-orders/manage-orders.module').then(m => m.ManageOrdersModule), canActivate: [AuthGuard] },
      { path: 'admin-tools', loadChildren: () => import('./pages/admin-tools/admin-tools.module').then(m => m.AdminToolsModule), canActivate: [AuthGuard] },
      { path: 'inventory', loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryModule ), canActivate: [AuthGuard] },
      { path: 'configurations', loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule ), canActivate: [AuthGuard] },
    ]
  },
  { path: 'home', component: HomeComponent },
  { path: 'master',loadChildren:()=> import('./oms-admin/oms-admin.module').then(m => m.OmsAdminModule).catch(err=>{console.log(err)})},
  { path: 'accounts', component: AccountsComponent,canActivate: [AuthGuard] },
  { path: 'sign-in', component: SignInComponent },
  { path: 'downtime', component: MaintenanceComponent },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false,preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
