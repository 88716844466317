
//import { JwtHelperService } from '@auth0/angular-jwt'

import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"
import { PostRoleRequset } from "../models/request.model"
import { Acknowledgement, GetAccessListResponse, GetRolesResponse, GetTenantsResponse } from "../models/response.model"

@Injectable({
  providedIn: 'root'
})
export class OmsAdminManageServices {
  private APIEndpoint = environment.APIEndpoint

  constructor(public http: HttpClient) {

  }
  public getRoles() {
    return this.http.get<GetRolesResponse>(`${this.APIEndpoint}/master/roles`)
  }

  public getAccess(){
    return this.http.get<GetAccessListResponse>(`${this.APIEndpoint}/master/access`)
  }

  public updateUserRole(RequestBody:PostRoleRequset){
    return this.http.post<Acknowledgement>(`${this.APIEndpoint}/master/role`,RequestBody)
  }

  public getTenants(limit:number,offset:number){
    return this.http.get<GetTenantsResponse>(`${this.APIEndpoint}/master/tenants?limit=${limit}&offset=${offset}`)
  }

}
