import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { DataTablesModule } from 'angular-datatables'
import { FooterComponent, NavigationComponent } from './components/layouts'
import { NotFoundComponent } from './components/custom'
import { MaintenanceComponent } from './components/custom/maintenance/maintenance.component'
import { OrderGroupPipe, OrderLabelPipe, OrderDeliveryChannelPipe, OrderTrackIdsPipe } from "../shared/pipes/dropdown-data-pipe"
import { StatusIconPipe, StatusButtonPipe, RowHighlightPipe, CurrentStatusPipe, OrderTypeColorPipe, StatusIconPipeMod } from "../shared/pipes/classes-pipe"
import { DateHandlePipe, DateRangeHandlePipe, OmsDatePipe, TotalNotesCount } from "../shared/pipes/general-pipes";
import { PaginationModule } from "ngx-bootstrap/pagination"
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { TabsModule, AccordionModule, TypeaheadModule, CollapseModule, ModalModule, ProgressbarModule, BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker'
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common'
import { TagInputModule } from 'ngx-chips'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { ToastrModule } from 'ngx-toastr'
import { OrderUpdateComponent } from '../pages/manage-orders/order-update/order-update.component'
import { UiSwitchModule } from 'ngx-ui-switch'
import { Ng2TelInputModule } from 'ng2-tel-input'
import { OmsCurrency } from './pipes/currency-pipe'

@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    NotFoundComponent,
    MaintenanceComponent,
    OrderGroupPipe,
    OrderLabelPipe,
    OrderDeliveryChannelPipe,
    OrderTrackIdsPipe,
    StatusIconPipe,
    StatusButtonPipe,
    RowHighlightPipe,
    CurrentStatusPipe,
    TotalNotesCount,
    OrderTypeColorPipe,
    StatusIconPipeMod,
    OrderUpdateComponent,
    OmsDatePipe,
    DateHandlePipe,
    DateRangeHandlePipe,
    OmsCurrency

  ],
  imports: [
    TypeaheadModule.forRoot(),
    ColorPickerModule,
    CollapseModule,
    TagInputModule,
    TabsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    DataTablesModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ToastrModule.forRoot(),
    ProgressbarModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#cfd3fc',
      switchColor: '#727CF5',
      defaultBgColor: 'white',
      defaultBoColor: '#CCCCCC',
      switchOffColor: '#CCCCCC',
      checkedTextColor: '#727CF5'
    })
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    StatusButtonPipe,
    StatusIconPipe,
    OrderTrackIdsPipe,
    OrderDeliveryChannelPipe,
    OrderTypeColorPipe,
    OrderGroupPipe,
    OrderLabelPipe,
    CommonModule,
    RouterModule,
    TooltipModule,
    TabsModule,
    PopoverModule,
    PaginationModule,
    BsDropdownModule,
    DataTablesModule,
    NavigationComponent,
    FooterComponent,
    RowHighlightPipe,
    CurrentStatusPipe,
    StatusIconPipeMod,
    TotalNotesCount,
    BsDatepickerModule,
    TypeaheadModule,
    ColorPickerModule,
    CollapseModule,
    TagInputModule,
    NgMultiSelectDropDownModule,
    AccordionModule,
    ModalModule,
    ToastrModule,
    ProgressbarModule,
    OmsDatePipe,
    DateHandlePipe,
    DateRangeHandlePipe,
    UiSwitchModule,
    Ng2TelInputModule,
    OmsCurrency

  ],
  providers: [
    OrderTypeColorPipe,
    OrderTrackIdsPipe,
    OrderGroupPipe,
    OrderLabelPipe,
    OrderDeliveryChannelPipe,
    StatusIconPipe,
    StatusButtonPipe,
    RowHighlightPipe,
    CurrentStatusPipe,
    StatusIconPipeMod,
    TotalNotesCount,
    ModalModule,
    OrderUpdateComponent,
    DatePipe,
    OrderUpdateComponent,
    BsModalService,
    BsModalRef,
    OmsDatePipe,
    DateHandlePipe,
    DateRangeHandlePipe,
    OmsCurrency,
    CurrencyPipe

  ],
  entryComponents: [OrderUpdateComponent],
})
export class SharedModule { }
