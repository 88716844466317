import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationComponent } from 'src/app/shared/components/layouts';
import { Menu } from 'src/app/shared/components/layouts/navigation/navigation.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { MessageService } from 'src/app/shared/utils/messageService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  amazonTokenExpiry:boolean = false;
  sellerPartnerId:string;
  expand:boolean = true;
  subscription: Subscription;
  menuItems: Array<any> = [
    {
        "featureName": "",
        "name": "Manage Orders",
        "icon": "cart",
        "path": "#",
        "tooltip": "Manage Orders",
        "submenu": [
            // {
            //     "name": "Orders Listing",
            //     "icon": "",
            //     "path": "/manage-orders/listing",
            //     "tooltip": "",
            //     "featureName": "",
            // },
            {
                "name": "Order List",
                "icon": "",
                "path": "/manage-orders/search",
                "tooltip": "",
                "featureName": "",
            },
            {
                "name": "Create Orders",
                "icon": "",
                "path": "/manage-orders/create",
                "tooltip": "",
                "featureName": "",
            }
        ]
    },
    {
        "featureName": "",
        "name": "Administration",
        "icon": "settings",
        "path": "#",
        "tooltip": "Administration",
        "submenu": [
            {
                "name": "System Configuration",
                "icon": "",
                "path": "/configurations",
                "tooltip": "",
                "featureName": "",
            },
            {
                "name": "Manage Order Labels",
                "icon": "",
                "path": "/admin-tools/manage-labels",
                "tooltip": "",
                "featureName": "",
            },
            {
                "name": "Manage Users",
                "icon": "",
                "path": "/admin-tools/manage-users",
                "tooltip": "",
                "featureName": "",
            },
            
        ]
    }
]
  activemenu="Find Orders"
  activeMainmenu='Manage Orders'
  constructor(
    private messageService:MessageService,
    private router: Router,
    public commonService: CommonService,
    private toast: ToastrService

    ) {
      var tenantId=sessionStorage.getItem('tenantId');
      this.commonService.getUserAccounts().subscribe(res=>{
      const correspondingTenant = res.tenants.find(tenant => tenant.id === parseInt(tenantId));
  
  if (correspondingTenant) {
    if (correspondingTenant.amazonTokenNearExpiry) {
      this.amazonTokenExpiry = true;
      this.sellerPartnerId = correspondingTenant.sellerPartnerId;
    }
    console.log('amazonTokenExpiry - ' + this.amazonTokenExpiry);
  } else {
    console.log('No corresponding tenant found for tenantId: ' + tenantId);
  }
      });
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if(JSON.parse(message.text)?.sidemenu)
      if(JSON.parse(message.text)?.sidemenu=='false'){
        this.expand = false
      }
      else if(JSON.parse(message.text)?.sidemenu=='true'){
        this.expand = true
      }
     console.log(message.text)
    });
   }

  

  ngOnInit(): void {

    this.menuItems.forEach((e:any)=>{
      e.submenu.forEach(submenu=>{
        if(this.router.url == submenu.path){
          this.activemenu = submenu.name
          this.activeMainmenu = e.name
        }
      })
      
    })
  }

  expandMenu(){
    this.expand = !this.expand
  }

  expandTrue(){
    this.expand = true
  }

  ChangeMenu(submenu,mainmenu){
    console.log(submenu,mainmenu)
    this.activemenu = submenu
    this.activeMainmenu = mainmenu
  }

  authorize(){
    
    this.commonService.amazonAuthorization(this.sellerPartnerId).subscribe(res=>{

      if(!res['isFailed']){
        window.location.href = `${res['message']}`;
      }else{
        this.toast.error(res['message'])
      }

    })
    
  }

}
