import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  modalRef: BsModalRef
  hiddenPassword: Boolean = true;
  isUmsTokenExist: Boolean = false;
  tabactive: string = "tab1";
  loader: boolean = true;
  tanants = null;
  thisYear: number;
  mainloader: boolean = false;
  inviteCode:boolean = false;
  codeVerifyloader: boolean;
  InviteCodeSubmitted: boolean;
  tab = -1;
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public activateRoute: ActivatedRoute,
    public commonService: CommonService,
    private toast: ToastrService,
    public modalModule: ModalModule, 
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.thisYear= (new Date()).getFullYear() 
    this.multitenancy()
  }

  /**
   * List user accounts 
   */
   multitenancy() {
    this.isUmsTokenExist = localStorage.getItem('token') ? true : false;
    if (this.isUmsTokenExist) {
      this.commonService.getUserAccounts().subscribe((response) => {
        if (response.acknowledgement.status == "SUCCESS") {
          this.tanants = response.tenants;
          this.loader = false;
        }
        else if (response.acknowledgement.status == "No tenants found") {
          // this.toast.warning(response.acknowledgement.message);
          this.tanants = []
          this.loader = false;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error.status)
        this.loader = false;
        if (error.status === 401) {
          this.toast.error('*Incorrect Credentials! Try again');
        } else {
          this.toast.error("Oops! Something went wrong");
        }
      })
    }
    else {
      this.loader = false
    }
  }

  /**
   * Create a Tenant instance
   */
   TenantInit(form: NgForm) {
    this.modalRef.hide()
    this.loader = true
    console.log(form.value)
    this.commonService.tenantInit(form.value).subscribe((res) => {
      console.log(res)
      this.multitenancy();
    }, (err) => {
      this.loader = false;
    })
  }

  ShowTenantModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' })
  }

  /**
   * Login to OMS
   */
   tenantAccess(item: any) {
    this.mainloader = true;
    sessionStorage.setItem('tenantId', item.id);
    this.commonService.tenantAccess(item.id).subscribe((res) => {
      this.mainloader = false;
      if (res.status == "SUCCESS") {
        this.commonService.setUserDetails()
        localStorage.setItem('Activetenant',JSON.stringify(item))
        this.commonService.GetColumnOrder().subscribe(res=>{
          if(res.status=="SUCCESS"){
            localStorage.setItem('columns',res.message)
            this.router.navigate(['/manage-orders/search'])
          }
        },(err=>{
          console.log(err)
          this.router.navigate(['/manage-orders/search'])
        }),()=>{
          this.router.navigate(['/manage-orders/search'])
        })
        
      }

    }, (error) => {
      console.log(error)
      this.mainloader = false;
      if (error.status === 401) {
        this.toast.error('*Incorrect Credentials! Try again');
      } else {
        this.toast.error("Oops! Something went wrong");
      }
    })
  }

  /**
   * Create Account through inviteCode
   */
   CreateViaCode(form:NgForm){
    this.InviteCodeSubmitted = true;
    if(form.status!=='INVALID'){
      this.codeVerifyloader = true;
      this.commonService.VerifyInviteCode(form.value.code).subscribe((response)=>{
        if(response && response.status=="SUCCESS"){
          this.toast.success(response.message)
          this.multitenancy()
          this.codeVerifyloader = false;
        }
      },(ErrorResponse:HttpErrorResponse)=>{
        this.codeVerifyloader = false;
        this.toast.error(ErrorResponse.error?.message)
      })
    }
   }

  logOut(){
    this.commonService.signOut()
    this.commonService.removeUserDetails()
    this.router.navigate(['/home'])
  }

  collapse(){
    this.inviteCode = !this.inviteCode;
  }

  expand(no){
    if(this.tab==no){
      this.tab = -1
    }
    else{
      this.tab = no;
    }
  }

}
