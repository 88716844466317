import { Injectable } from "@angular/core";
import { Order, OrderEntityResponse, OrderResponse } from "../models/services-response/order-response.model";
import { OrderRequest } from "../models/service-request/order-request.model";
import { Observable, from } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { LabelsResponse } from "../models/services-response/labels-response.model";
import { DeliveryChannelsResponse } from "../models/services-response/deliveryChannels.response.model";
import { GroupsResponse } from "../models/services-response/groups-response.model";
import { SalesChannelsResponse } from "../models/services-response/salesChannels-response.model";
import { StatusResponse } from "../models/services-response/status-response.model";
import { FulfillmentCentersResponse } from "../models/services-response/fulfillmentCenters.response.model";
import { Acknowledgement } from "../models/services-response/acknowledgement-response.model";
import { ErrorOrdersRequest } from "../models/service-request/errorOrders-request.model";
import { ErroredOrdersResponse } from "../models/services-response/errored-orders-response.model";
import { ShippingErrorOrdersRequest } from "../models/service-request/shippingErrorOrders-request.model";
import { ShippingErrorsResponse } from "../models/services-response/shipping-errors-response.model";
import { ZipCodeMap } from "../models/services-response/zipcode-map.model";
import { TagLabelTypeResponse, TagLabelsResponse, TagLabel } from "../models/services-response/tag-labels-response.model";
import { LabelRequest } from "../models/service-request/label-request.model";
import { SkuRulesResponse } from "../models/services-response/skuRules-response.model";
import { SkuRuleValuesResponse } from "../models/services-response/skuRuleValues-response.model";
import { LineItemResponse } from "../models/services-response/lineitem-response.model";
import { lineItemRequest } from "../models/service-request/lineitem-request.model";
import {
  GenerateDeliveryLabelsRequest,
  GenerateLineItemLabelsRequest,
} from "../models/service-request/generate-labels-request.model";
import { GenerateLabelsResponse } from "../models/services-response/generate-labels-response.model";
import { CommonService } from "./common.service";
import { EntityResponse } from "../models/services-response/entity-response.model";
import { OrderHistoryResponse } from "../models/services-response/order-history.model";
import { FinancialStatusResponse } from "../models/services-response/financialStatus.response.model";
import { OFTypesResponse } from "../models/services-response/ofType-response.model";
import { UserFulfillmentResponse } from "../models/services-response/fulfillment-user-response.model";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private APIEndpoint = environment.APIEndpoint;
  private APIEndpointOFC = environment.APIEndpointOFC;
  private APIEndpointShipment = environment.APIEndpointShipment;
  constructor(public http: HttpClient, private commonService: CommonService) { }
  public getOrders(requestBody: OrderRequest): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.APIEndpoint}/orders`, { params: requestBody.toHttpParams() });
  }
  public getOrder(orderId) {
    return this.http.get<OrderResponse>(`${this.APIEndpoint}/orders/${orderId}`);
  }
  public getLabels(): Observable<LabelsResponse> {
    return this.http.get<LabelsResponse>(`${this.APIEndpoint}/labels`);
  }
  public saveLabel(request: LabelRequest, lId): Observable<LabelsResponse> {
    return this.http.post<LabelsResponse>(`${this.APIEndpoint}/labels/${lId}`, request);
  }
  public getGroups(): Observable<GroupsResponse> {
    return this.http.get<GroupsResponse>(`${this.APIEndpoint}/groups`);
  }
  public getStatus(): Observable<StatusResponse> {
    return this.http.get<StatusResponse>(`${this.APIEndpoint}/orderstatus`);
  }
  public getDeliveryChannels(): Observable<DeliveryChannelsResponse> {
    return this.http.get<DeliveryChannelsResponse>(`${this.APIEndpoint}/deliverychannels`);
  }
  public getSalesChannels(): Observable<SalesChannelsResponse> {
    return this.http.get<SalesChannelsResponse>(`${this.APIEndpoint}/saleschannels`);
  }
  public updateOrder(orderId, updates): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(`${this.APIEndpoint}/orders/${orderId}`, updates);
  }
  public getErroredOrders(requestBody: ErrorOrdersRequest) {
    return this.http.get<ErroredOrdersResponse>(`${this.APIEndpointOFC}/erroredorders`, { params: requestBody.toHttpParams() });
  }
  public generatePedalsExcel(date) {
    const params = new HttpParams().set("deliveryDate", date.toString());
    return this.http.get(`${this.APIEndpoint}/generateExcels/pedals`, { params: params });
  }
  public uploadExcelForPedals(file: File): Observable<Acknowledgement> {
    const formdata = new FormData();
    formdata.append("file", file);
    return this.http.post<Acknowledgement>(`${this.APIEndpoint}/excels/upload/pedals`, formdata);
  }
  public generateExcelForPedals(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/generateExcel`, { params: orderRequest.toHttpParams() });
  }
  public downloadExcel(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/downloadExcel`, { params: orderRequest.toHttpParams(), responseType: "blob" });
  }
  public generateKitchenLabels(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/generateKitchenLabels`, { params: orderRequest.toHttpParams() });
  }

  public getFinancialStatus() {
    return this.http.get<FinancialStatusResponse>(`${this.APIEndpoint}/financial_status`);
  }

  public downloadKitchenLabels(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/downloadKitchenLabels`, {
      params: orderRequest.toHttpParams(),
      responseType: "blob",
    });
  }
  public generateDeliveryLabels(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/generateDeliveryLabels`, { params: orderRequest.toHttpParams() });
  }
  public downloadDeliveryLabels(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/downloadDeliveryLabels`, {
      params: orderRequest.toHttpParams(),
      responseType: "blob",
    });
  }
  public generateDeliveryLabelsv2(orderId: GenerateDeliveryLabelsRequest): Observable<GenerateLabelsResponse> {
    return this.http.post<GenerateLabelsResponse>(`${this.APIEndpoint}/generateDeliveryLabels`, orderId);
  }
  public generateItemLabelsv2(lineItemId: GenerateLineItemLabelsRequest): Observable<GenerateLabelsResponse> {
    return this.http.post<GenerateLabelsResponse>(`${this.APIEndpoint}/generateItemLabels`, lineItemId);
  }
  public downloadDeliveryLabelsv2(labelId: string) {
    window.open(`${this.APIEndpoint}/download/labels/shipping/${labelId}?token=${this.commonService.getToken().split(" ")[1]}`);
  }
  public downloadItemLabelsv2(labelId: string) {
    window.open(`${this.APIEndpoint}/download/labels/item/${labelId}?token=${this.commonService.getToken().split(" ")[1]}`);
  }
  public getShippingErroredOrders(request: ShippingErrorOrdersRequest): Observable<ShippingErrorsResponse> {
    return this.http.get<ShippingErrorsResponse>(`${this.APIEndpointShipment}/v1/wish/delivery/failed-orders`, {
      params: request.toHttpParams(),
    });
  }
  public processBatch(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/processBatch`, { params: orderRequest.toHttpParams() });
  }
  public getBatchStatus(orderRequest: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/checkBatchStatus`, { params: orderRequest.toHttpParams() });
  }
  public hideErroredOrder(request: ErrorOrdersRequest) {
    return this.http.post(`${this.APIEndpointOFC}/hideerroredorder/${request.orderId}`, "");
  }
  public getTaskNumbers(request: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/taskNumber`, { params: request.toHttpParams() });
  }
  public hideShipmentErroredOrder(id) {
    return this.http.post(`${this.APIEndpointShipment}/v1/wish/delivery/hideShippingError/${id}`, "");
  }
  public generateDcExcel(request: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/generateExcelForDeliveryChannel`, { params: request.toHttpParams() });
  }
  public generateExcelForKitchenLabels(request: OrderRequest) {
    return this.http.get(`${this.APIEndpoint}/generateExcelForKitchenLabel`, { params: request.toHttpParams() });
  }

  // configuration services
  public getZipMapdata(): Observable<ZipCodeMap> {
    return this.http.get<ZipCodeMap>(`${this.APIEndpoint}/zipMap`);
  }
  public updateZipMapData(data: ZipCodeMap) {
    return this.http.post(`${this.APIEndpoint}/zipMap`, data);
  }
  public getTagLabels(): Observable<TagLabelsResponse> {
    return this.http.get<TagLabelsResponse>(`${this.APIEndpoint}/tagLabels`);
  }
  public getTagLabelType(): Observable<TagLabelTypeResponse> {
    return this.http.get<TagLabelTypeResponse>(`${this.APIEndpoint}/tagLabelTypes`);
  }
  public saveTagLabel(data: TagLabel) {
    return this.http.post(`${this.APIEndpoint}/tagLabels`, data);
  }
  public getSkuRules(): Observable<SkuRulesResponse> {
    return this.http.get<SkuRulesResponse>(`${this.APIEndpoint}/rules`);
  }
  public getSkusForRule(rule): Observable<SkuRuleValuesResponse> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("rule", rule);
    return this.http.get<SkuRuleValuesResponse>(`${this.APIEndpoint}/ruleValues`, { params: httpParams });
  }
  public addOrEditSkuForRule(request): Observable<SkuRuleValuesResponse> {
    return this.http.post<SkuRuleValuesResponse>(`${this.APIEndpoint}/ruleValues`, request);
  }
  public removeSkuFromRule(ruleValueId) {
    return this.http.delete<Acknowledgement>(`${this.APIEndpoint}/ruleValues/${ruleValueId}`);
  }
  public updateLineItem(obj: lineItemRequest) {
    return this.http.post(`${this.APIEndpoint}/orders/updateItems`, obj);
  }
  public mailnotification(id): Observable<any> {
    return this.http.get(`${this.APIEndpoint}/notifyuser/${id}`)
  }

  public fetchEntities(): Observable<EntityResponse> {
    return this.http.get<EntityResponse>(`${this.APIEndpoint}/entity`);
  }

  public fetchOrderEntities(orderID): Observable<OrderEntityResponse> {
    return this.http.get<OrderEntityResponse>(`${this.APIEndpoint}/orders/entity/${orderID}`);
  }

  public addOrderEntity(orderID, entity): Observable<OrderEntityResponse> {
    return this.http.post<OrderEntityResponse>(`${this.APIEndpoint}/orders/entity/${orderID}`, entity);
  }

  public getOrderHistoty(orderId): Observable<OrderHistoryResponse> {
    return this.http.get<OrderHistoryResponse>(`${this.APIEndpoint}/orderHistory/${orderId}`);
  }

  public getOFTypes(type: string): Observable<OFTypesResponse> {
    return this.http.get<OFTypesResponse>(`${this.APIEndpoint}/oftypes?type=${type}`)
  }

  public getPaymentMethods(){
    return this.http.get(`${this.APIEndpoint}/payment_methods`)
  }


  // fulfillment center
  public getFulfillmentCenters(status:string|null): Observable<FulfillmentCentersResponse> {
    let httpParams = new HttpParams();
    if(status != null){
      httpParams = httpParams.append("status", status);
    }
    return this.http.get<FulfillmentCentersResponse>(`${this.APIEndpoint}/fulfillmentcenters`,{ params: httpParams });
  }

  public addOrUpdateFulfillmentCenter(payload, id): Observable<FulfillmentCentersResponse>{
      return this.http.post<FulfillmentCentersResponse>(`${this.APIEndpoint}/fulfillment_center/${id}`, payload);
  }

  public userFulfillmentCenters():Observable<UserFulfillmentResponse>{
    return this.http.get<UserFulfillmentResponse>(`${this.APIEndpoint}/users/fulfillment_center`)
  }

  public mapUserFulfillmentCenter(payload):Observable<UserFulfillmentResponse>{
    return this.http.post<UserFulfillmentResponse>(`${this.APIEndpoint}/users/fulfillment_center`,payload)
  }

  public exportToPdf(orderId:string){
    return this.http.get(`${this.APIEndpoint}/orderStatement/${orderId}`,{responseType: 'arraybuffer'})
  }

}
