// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  origin:window.location.origin ,
  //APIEndpointOMS: "https://staging.oms.bytetale.com",
  APIEndpointUMS: "https://ums.bytetale.com",
  APIEndpoint: "https://storioms.bytetale.com/orderfulfillmentservices/v2",
  APIEndpointOFC: "https://staging.oms.bytetale.com/of-shopify-connector",
  APIEndpointShipment: "https://staging.oms.bytetale.com/shipment",
  APIEndpointConnector: "https://staging.oms.bytetale.com/amzn_noths-shopify-connector",
  APIEndpointSalesConnector : "https://staging.oms.bytetale.com/salesconnector",
  SalesConnectorEndpoint : "https://storioms.bytetale.com/salesconnector/v1" , 
  production: false
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
